.yt-lite {
  background-color: #000;
  background-position: 50%;
  background-size: cover;
  contain: content;
  cursor: pointer;
  display: block;
  height: 100%;
  position: relative;
  width: 100%;

  &::before {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==');
    background-position: top;
    background-repeat: repeat-x;
    content: '';
    display: block;
    height: 198px;
    padding-bottom: 50px;
    position: absolute;
    top: 0;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    width: 1px;
  }

  &::after {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }

  > iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  > .lty-playbtn {
    background-color: #212121;
    border-radius: 14%;
    height: 46px;
    left: 50%;
    opacity: 0.8;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    width: 70px;
    z-index: 1;

    &::before {
      border-color: transparent transparent transparent #fff;
      border-style: solid;
      border-width: 11px 0 11px 19px;
      content: '';
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  &:hover {
    > .lty-playbtn {
      background-color: #f00;
      opacity: 1;
    }
  }
}

.yt-lite.lyt-activated {
  cursor: unset;

  &::before {
    opacity: 0;
    pointer-events: none;
  }

  > .lty-playbtn {
    opacity: 0;
    pointer-events: none;
  }
}
