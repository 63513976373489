.filter-brand-light {
  filter: invert(63%) sepia(60%) saturate(1039%) hue-rotate(173deg) brightness(87%) contrast(93%);
}

.filter-accent-cyan {
  filter: invert(98%) sepia(91%) saturate(7494%) hue-rotate(105deg) brightness(105%) contrast(104%);
}

.decorated {
  overflow: hidden;
  text-align: center;
}

.decorated > span {
  display: inline-block;
  position: relative;
}

.decorated > span::before,
.decorated > span::after {
  border-bottom: 2px solid;
  content: '';
  margin: 0 32px;
  position: absolute;
  top: 50%;
  width: 100vw;
}

.decorated > span::before {
  right: 100%;
}

.decorated > span::after {
  left: 100%;
}

.textoutline-white {
  text-shadow:
    -1px -1px 0 #fff,
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
    1px 1px 0 #fff;
}

.prose ul > li::before {
  background-color: #0004ff;
}

.container--bleed-left,
.container--bleed-right {
  &:not([class*='sidebar-']) {
    max-width: 100%;
    @screen xs {
      max-width: calc(50vw + 240px);
    }
    @screen sm {
      max-width: calc(50vw + 300px);
    }
    @screen md {
      max-width: calc(50vw + 450px);
    }
    @screen lg {
      max-width: calc(50vw + 472px);
    }
    @screen xl {
      max-width: calc(50vw + 764px);
    }
    @screen 2xl {
      max-width: calc(50vw + 748px);
    }
  }
}

.container--bleed-left {
  &:not([class*='sidebar-']) {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
}

.container--bleed-right {
  &:not([class*='sidebar-']) {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
}

.container--bleed-left.sidebar-bleed {
  @screen md {
    margin-left: 0 !important;
    max-width: calc(50vw + 450px);
    padding-left: 0 !important;
  }
  @screen lg {
    max-width: calc(50vw + 472px);
  }
  @screen xl {
    max-width: calc(50vw + 764px);
  }
  @screen 2xl {
    max-width: calc(50vw + 748px);
  }
}

.container--bleed-right.sidebar-bleed {
  @screen md {
    margin-right: 0 !important;
    max-width: calc(50vw + 450px);
    padding-right: 0 !important;
  }
  @screen lg {
    max-width: calc(50vw + 472px);
  }
  @screen xl {
    max-width: calc(50vw + 764px);
  }
  @screen 2xl {
    max-width: calc(50vw + 748px);
  }
}

.container--bleed-right.no-sidebar {
  max-width: none;
  @screen md {
    margin-right: 0 !important;
    max-width: calc(50vw + 450px);
    padding-right: 0 !important;
  }
  @screen lg {
    max-width: calc(50vw + 600px);
  }
  @screen xl {
    max-width: calc(50vw + 764px);
  }
  @screen 2xl {
    max-width: calc(50vw + 748px);
  }
}

.bg-overlay {
  background: rgba(21, 34, 49, 0.75);
  bottom: 0;
  content: ' ';
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.bg-overlay[data-style='blur'] {
  filter: blur(4px);
}

.bg-overlay[data-style='blur-color'] {
  filter: blur(6px);
  mix-blend-mode: luminosity;
}

.bg-overlay[data-style='1'] {
  background: radial-gradient(circle at 55%, rgba(5, 7, 134, 0.9) 0%, rgba(5, 7, 134, 0.66) 100%);
}

.bg-overlay[data-style='2'] {
  background: linear-gradient(to left, rgba(11, 76, 140, 0) 20%, rgba(11, 76, 140, 0.85) 100%);
}

.bg-overlay[data-style='3'] {
  background: linear-gradient(to left, rgba(21, 34, 49, 0) 20%, rgba(21, 34, 49, 0.85) 100%);
}

.bg-overlay[data-style='4'] {
  background: linear-gradient(to bottom, rgba(21, 34, 49, 0) 20%, rgba(21, 34, 49, 0.85) 100%);
}

.bg-overlay[data-style='5'] {
  background: linear-gradient(
    35deg,
    rgba(0, 4, 255, 0.25) 1%,
    rgba(3, 3, 58, 0.6) 10%,
    rgba(3, 3, 58, 0.85) 40%,
    rgba(3, 3, 58, 0.55) 55%,
    rgba(3, 3, 58, 0.2) 65%,
    rgba(0, 4, 255, 0) 100%
  );
  @screen lg {
    background: linear-gradient(
      75deg,
      rgba(0, 4, 255, 0.25) 1%,
      rgba(3, 3, 58, 0.6) 10%,
      rgba(3, 3, 58, 0.85) 25%,
      rgba(3, 3, 58, 0.55) 50%,
      rgba(3, 3, 58, 0.2) 60%,
      rgba(0, 4, 255, 0.1) 100%
    );
  }
}

.bg-overlay[data-style='6'] {
  background: linear-gradient(
    35deg,
    rgba(3, 3, 58, 0) 0%,
    rgba(3, 3, 58, 0.3) 15%,
    rgba(3, 3, 58, 0.66) 30%,
    rgba(3, 3, 58, 0.45) 50%,
    rgba(3, 3, 58, 0.2) 60%,
    rgba(0, 4, 255, 0.1) 100%
  );
}

.bg-overlay[data-style='jtw'] {
  background: linear-gradient(50deg, rgba(84, 196, 222) 5%, rgba(63, 240, 194) 100%);
}

.bg-overlay[data-style='hero'] {
  background: linear-gradient(
    14deg,
    rgba(3, 3, 58, 0.85) 12%,
    rgba(3, 3, 58, 0.55) 66%,
    rgba(3, 3, 58, 0) 90%
  );
  @screen lg {
    background: linear-gradient(
      45deg,
      rgba(0, 4, 255, 0.3) 0%,
      rgba(3, 3, 58, 0.55) 6%,
      rgba(3, 3, 58, 0.85) 24%,
      rgba(3, 3, 58, 0.6) 42%,
      rgba(3, 3, 58, 0) 70%,
      rgba(3, 3, 58, 0) 100%
    );
  }
}

.bg-overlay[data-style='cpa'] {
  background: none;
  @screen lg {
    background: linear-gradient(
      74deg,
      rgba(0, 4, 255, 0.2) 1%,
      rgba(3, 3, 58, 0.4) 15%,
      rgba(3, 3, 58, 0.75) 25%,
      rgba(3, 3, 58, 0.5) 32%,
      rgba(3, 3, 58, 0) 45%,
      rgba(3, 3, 58, 0) 100%
    );
  }
}

.bg-overlay[data-style='sonic'] {
  background: linear-gradient(
    20deg,
    rgba(5, 7, 134, 0.85) 5%,
    rgba(5, 7, 134, 0.7) 50%,
    rgba(5, 7, 134, 0) 90%
  );
  @screen md {
    background: linear-gradient(
      110deg,
      rgba(5, 7, 134, 0.9) 10%,
      rgba(5, 7, 134, 0.75) 50%,
      rgba(5, 7, 134, 0) 72%
    );
  }
}

.has-dots::after {
  background: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 100 100'%3E%3Crect x='0' y='0' width='12' height='12' fill-opacity='1' fill='%230004FF'/%3E%3C/svg%3E");
  background-position: left bottom;
  bottom: 7px;
  content: '';
  height: 32%;
  left: -0.5rem;
  position: absolute;
  width: 31.5%;
  z-index: -20;
  @screen lg {
    left: 19px;
  }
  @include dark {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 100 100'%3E%3Crect x='0' y='0' width='12' height='12' fill-opacity='1' fill='%2303ff00'/%3E%3C/svg%3E");
  }
}

.has-dots.cyan::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 100 100'%3E%3Crect x='0' y='0' width='12' height='12' fill-opacity='1' fill='%2300ffff'/%3E%3C/svg%3E");
}

.has-dots.top::after {
  background-position-y: top;
  bottom: auto;
  top: 2px;
}

.has-dots.right::after {
  background-position-x: right;
  left: auto;
  right: -0.5rem;
  @screen lg {
    left: auto;
    right: 5px;
  }
}

.has-dots.top-lg::after {
  @screen lg {
    background-position-y: top;
    bottom: auto;
    top: 2px;
  }
}

.has-dots.full-dots::after {
  height: 100%;
}

.embed-responsive {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
  }

  .embed-responsive-item,
  embed,
  iframe,
  object,
  video,
  > div,
  > button {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.embed-responsive-21by9 {
  &::before {
    padding-top: 42.857143%;
  }
}

.embed-responsive-16by9 {
  &::before {
    padding-top: 56.25%;
  }
}

.embed-responsive-4by3 {
  &::before {
    padding-top: 75%;
  }
}

.embed-responsive-1by1 {
  &::before {
    padding-top: 100%;
  }
}

.video-wrapper {
  height: calc(82vh - 96px);
  max-height: 864px;
  min-height: 480px;
  @screen lg {
    height: calc(100vh - 48px);
    max-height: 100%;
  }

  video {
    display: inline-block;
    height: auto;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    opacity: 0.5;
    user-select: none;
    width: auto;
  }

  &.cpa {
    height: 88vw;
    min-height: 360px;
    @screen md {
      height: calc(100vh - 88px);
      max-height: 100%;
    }
    @screen lg {
      height: calc(100vh - 24px);
    }
  }
}

.video-hero {
  @screen md {
    margin-top: -13vh;
  }
}

.offset-bg-text {
  padding: 24px;
  @screen md {
    padding: 5vh;
  }
}

.offset-bg-img {
  padding: 0;
  @screen md {
    padding: 13vh 0;
  }

  picture {
    max-width: calc(100% + 4rem);
    transform: none;
    z-index: -1;
    @screen md {
      max-width: calc((100% / 6) * 5);
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% + 4rem);
    }

    img {
      filter: blur(1px);
      object-position: 60% 62%;
    }
  }
}

.offset-bg-cta {
  margin-left: 0;
  @screen md {
    margin-left: auto;
    max-width: calc((100% / 6) * 5);
  }
}

.promo-item {
  flex: 0 1 33.333333%;
  min-width: 288px;
  @screen md {
    min-width: 224px;
  }

  &:focus {
    outline: 2px solid #0ff !important;
    outline-offset: 2px;
  }

  .promo-title i {
    @apply text-green-light dark:text-accent-neon;
  }

  &:hover {
    .promo-title i {
      @apply text-accent-mint dark:text-accent-yellow;
    }
  }
}

.promo-item:last-child {
  margin-right: 0 !important;
}

.dark {
  .eapps-instagram-feed {
    .eapps-instagram-feed-posts-item {
      background-color: theme('colors.black') !important;

      a {
        @apply opacity-80;
        color: theme('colors.white.DEFAULT') !important;

        &:hover {
          @apply opacity-100;
        }
      }
    }

    .eapps-instagram-feed-posts-item-text {
      color: theme('colors.white.DEFAULT') !important;
    }

    .eapps-instagram-feed-posts-grid-load-more {
      border-color: theme('colors.white.DEFAULT') !important;
      color: theme('colors.white.DEFAULT') !important;

      &:hover {
        background-color: theme('colors.brand.DEFAULT') !important;
        border-color: theme('colors.brand.DEFAULT') !important;
      }
    }
  }
}

.toggle-group {
  li {
    @apply mt-2 mb-4;

    button {
      @apply flex text-left w-full;

      &:focus {
        outline-color: theme('colors.gray.light');
      }

      &::after {
        @apply ml-auto transition-all text-brand-bright;
        @include fas;
        content: fa-content($fa-var-angle-down);
        @include dark {
          @apply text-accent-neon;
        }
      }

      &:hover {
        &::after {
          @apply text-brand;
          @include dark {
            @apply text-accent-yellow;
          }
        }
      }

      &.active {
        @apply font-shield-bold;

        &::after {
          transform: rotate(-180deg);
        }
      }
    }

    .answer {
      > *:last-child {
        @apply mb-0;
      }
    }
  }
}

.toggle-cell {
  max-width: calc(100vw - 2rem);
  @screen sm {
    max-width: 584px;
  }
  @screen md {
    max-width: 100vw;
  }
}

.toggle-link {
  button {
    @apply block mx-auto py-3 px-8 relative;

    &:hover {
      &::after {
        @apply text-brand-bright;
        @include dark {
          @apply text-white;
        }
      }
    }

    &::after {
      @apply absolute right-3 transition-all text-brand inline-block;
      @include fas;
      content: fa-content($fa-var-angle-down);
      @include dark {
        @apply text-accent-neon;
      }
    }

    &.active {
      &::after {
        transform: rotate(-180deg);
      }
    }
  }
}

.notification {
  @screen lg {
    z-index: 9999;
  }
}

.chevr {
  display: block;
  height: 2rem;
  overflow: hidden;
  width: 1rem;

  &::after {
    border: 0.75rem solid;
    content: "''";
    display: block;
    height: 4rem;
    margin-left: -2.375rem;
    margin-top: -1rem;
    transform: scaleX(0.5) rotate(45deg);
    width: 4rem;
  }
}

.disclaimer {
  display: block;
  font-size: 0.6875rem;
  font-style: italic;
  opacity: 0.9;
}

.divider {
  height: 100%;
  width: 0.125rem;
}

.no-p {
  &-first {
    > *:first-child {
      padding-top: 0;
    }
  }

  &-last {
    > *:last-child {
      padding-bottom: 0;
    }
  }
}

.no-m {
  &-first {
    > *:first-child {
      margin-top: 0;
    }
  }

  &-last {
    > *:last-child {
      margin-bottom: 0;
    }
  }
}

.player-wrapper {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;

  > * {
    height: 100% !important;
    left: 0;
    position: absolute;
    top: 0;
    width: 100% !important;
  }
}

.scroller-item {
  @apply mr-4;
  flex: 1 0 30%;
  height: 66vh;
  min-width: 336px;
  overflow: hidden;
}

.scroller-item.scroller-intro {
  flex: 1 0 40%;
}

.scroller-item img {
  height: 100%;
  width: auto;
}

.expander {
  p:last-of-type {
    margin-bottom: 0;
  }

  .copy {
    @apply transition-transform;
  }
}

.diff-meter {
  i {
    &:nth-of-type(1) {
      color: #73be44;
    }

    &:nth-of-type(2) {
      color: #8cbe44;
    }

    &:nth-of-type(3) {
      color: #a0be44;
    }

    &:nth-of-type(4) {
      color: #b5bc41;
    }

    &:nth-of-type(5) {
      color: #f7a81e;
    }

    &:nth-of-type(6) {
      color: #f78f1e;
    }

    &:nth-of-type(7) {
      color: #f58220;
    }

    &:nth-of-type(8) {
      color: #f36e21;
    }

    &:nth-of-type(9) {
      color: #f15822;
    }

    &:nth-of-type(10) {
      color: #f04a23;
    }

    &:nth-of-type(11) {
      color: #ed2224;
    }
  }
}

// Gallery
.slick-vertical {
  &.gallery-thumbs {
    height: 61vh;
    overflow: hidden;

    .slick-list {
      height: 100% !important;
    }

    .slick-slide {
      opacity: 0.6;

      &.slick-current {
        border: 0;
        opacity: 1;
      }
    }

    .thmb {
      height: 9vh !important;
      object-fit: cover;
      object-position: center center;
      width: 100%;
    }
  }
}

.gallery-container {
  .gallery-image {
    width: 100%;

    .fullimage {
      height: 33vh;
      object-fit: cover;
      object-position: center center;
      width: 100%;
    }
  }

  .slick-prev,
  .slick-next {
    @apply bg-brand-bright dark:bg-accent-neon rounded h-11 w-10;
    bottom: initial;
    left: initial;
    position: absolute;
    right: 1rem;
    top: 33vh;
    z-index: 10;

    &::before {
      @apply text-2xl text-white dark:text-brand-navy;
      @include icon;
      @include icon--arrow-right;
      opacity: 1;
    }

    &:hover,
    &:focus {
      @apply bg-brand-hover;

      &::before {
        @apply text-white;
      }
    }
  }

  .slick-prev {
    right: 3.85rem;

    &::before {
      @include icon--arrow-left;
    }
  }

  .slick-disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover,
    &:focus {
      @apply bg-brand-light text-brand-navy;
    }
  }
}

@screen md {
  .gallery-container {
    .gallery-image {
      .fullimage {
        height: 61vh;
      }
    }

    .slick-prev,
    .slick-next {
      top: 61vh;
    }
  }
}

.day-select label {
  @apply bg-transparent sm:px-3 lg:px-2 xl:px-3 py-2 border border-brand-bright dark:border-gray-light leading-none cursor-pointer block relative text-center;
  margin-left: -1px;
}

.day-select label:hover {
  @apply bg-white dark:bg-gray-dark;
}

.day-select label:first-of-type,
.day-select label:first-of-type .checkmarked {
  @apply rounded-l;
  margin-left: 0;
}

.day-select label:last-of-type,
.day-select label:last-of-type .checkmarked {
  @apply rounded-r;
}

.day-select input {
  @apply absolute opacity-0;
}

.day-select .checkmarked {
  @apply absolute inset-0 bg-white dark:bg-gray border-brand dark:border-gray-light hidden z-0;
}

.day-select input:checked ~ .checkmarked {
  @apply block;
}

.day-select input:focus ~ span {
  @apply outline-black dark:outline-accent-neon;
}

.eventspace-select input:focus ~ span {
  outline: 2px solid #0ff;
  outline-offset: 2px;
}

.tippy-box {
  @apply shadow-lg;
  background-color: #fff !important;
  color: #333 !important;
}

.tippy-arrow {
  color: #fff !important;
}

.tippy-content {
  padding: 1rem 1.5rem !important;
}

.splide,
.splide__track {
  height: 100%;
}

.splide__arrow {
  @apply relative !bg-brand dark:!bg-accent-cyan !h-8 !w-8 lg:!h-10 lg:!w-10 !opacity-100 !bottom-0 md:!bottom-auto !top-auto md:!top-1/2 !translate-y-0 md:!-translate-y-1/2;
}

.splide__arrow svg {
  @apply relative !fill-accent-cyan dark:!fill-brand;
}

.splide__arrow--next {
  @apply relative !right-0;
}

.splide__arrow--prev {
  @apply relative !left-0;
}

.hero .splide__arrow {
  display: none !important;
}

.hero .splide__pagination {
  @apply gap-0.5;

  bottom: -20px !important;
  left: 12px !important;
  padding: 0 !important;
  right: initial !important;

  li {
    button {
      @apply bg-gray-warm dark:bg-gray-light;

      opacity: 1 !important;

      &.is-active {
        @apply bg-brand-bright dark:bg-accent-cyan;
      }
    }
  }

  @screen sm {
    bottom: 4px !important;
    left: initial !important;
    right: 4px !important;
  }
}

.splide__pagination {
  @apply gap-0.5;
  padding: 0 !important;

  li {
    button {
      @apply bg-gray-warm dark:bg-gray-light;

      opacity: 1 !important;

      &.is-active {
        @apply bg-brand-bright dark:bg-accent-cyan;
      }
    }
  }
}

.eventspace-wrapper .splide__pagination {
  @apply gap-2 bottom-0 md:-bottom-8 -translate-y-1/2 md:-translate-y-0;
}

// leaflet map

.leaflet-control-attribution {
  display: none;
}

/* stylelint-disable */
#eventPromos {
  .splide__pagination {
    @apply gap-0.5;
    bottom: -44px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    padding: 0 !important;
    right: initial !important;
    z-index: 10 !important;
    li {
      button {
        @apply bg-gray-warm dark:bg-gray-light;

        opacity: 1 !important;

        &.is-active {
          @apply bg-brand-bright dark:bg-accent-cyan;
        }
      }
    }
  }
}
@media (max-width: 900px) {
  #eventPromos {
    .splide__pagination {
      bottom: -32px !important;
    }
  }
}

.splide__pagination__page {
  height: 6px !important;
  width: 6px !important;

  @screen sm {
    height: 8px !important;
    width: 8px !important;
  }
}

/* Fullcalendar */
.tippy-box {
  min-width: 220px;
}

.tippy-cowan {
  background-color: rgb(13 17 23) !important;
  .tippy-content {
    padding: 0.75rem 1rem !important;
  }
}

.fc,
.fc h2,
.fc-toolbar-title {
  @apply text-white;
}

.fc-icon {
  font-family: 'Font Awesome 6 Free' !important;
  font-weight: 900;
}
.fc-icon-chevron-right::before {
  content: '\f054';
}
.fc-icon-chevron-left::before {
  content: '\f053';
}
.fc-list-day-text,
.fc-list-day-side-text,
.fc-col-header-cell-cushion,
.fc-daygrid-day-number {
  border: 0 !important;
}

@media (max-width: 768px) {
  .fc-cell-shaded {
    background-color: rgb(5, 7, 134) !important;
  }
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 0;
  }
  .fc .fc-toolbar-title {
    font-size: 1rem !important;
  }
  .fc-event {
    pointer-events: none;
  }
  .fc-list-event {
    display: flex;
    flex-direction: column;
  }
  .fc-list-event-time {
    background-color: rgba(208, 208, 208, 0.3);
    width: 100% !important;
    font-family:
      ShieldSans Bold,
      Arial,
      sans-serif !important;
  }
  .fc-list-event-graphic {
    display: none;
  }
  .fc-list-event-title {
    border: 0 !important;
    font-family:
      ShieldSans Bold,
      Arial,
      sans-serif !important;
    margin-bottom: 1rem;
    a {
      display: none;
    }
  }
  .fc-list-event-title span {
    font-family:
      ShieldSans Regular,
      Arial,
      sans-serif !important;
  }
  .fc-event-description {
    margin-top: 0.5rem;
    a {
      width: fit-content;
      display: block;
    }
  }
}

@media (min-width: 769px) {
  .fc .fc-scroller,
  .fc .fc-scroller-harness {
    overflow: visible !important;
  }
  .fc .tippy-box {
    width: 100vw;
    max-width: 600px !important;
  }
  .fc-daygrid {
    z-index: 0 !important;
  }
  .fc-daygrid-event {
    font-size: 1em !important;
    border: 0 !important;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgb(11, 76, 140, 0.05);
    padding: 1px;
  }
  .fc-event-time,
  .fc-event-title {
    white-space: pre-wrap;
  }
  .fc-daygrid-event-dot {
    display: none;
  }
  .fc .fc-daygrid-event {
    align-items: flex-start;
  }
}

#tgChatbot {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 50;
  background-color: #fff;
}

/* stylelint-enable */
