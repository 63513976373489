.table-responsive {
  max-width: 100%;
  overflow: auto;

  table {
    position: relative;
    table-layout: auto;
    width: 100%;
    @screen lg {
      table-layout: fixed;
    }

    thead {
      th {
        position: sticky;
      }
    }
  }
}

.table {
  @apply mx-0 my-4 w-full;
  border-collapse: collapse;
  overflow-x: auto;
  table-layout: fixed;

  th {
    @apply border-b border-gray-light font-shield-bold p-4 text-left;
    @include dark {
      @apply border-gray;
    }
  }

  td {
    @apply border-b border-gray-light p-4;
    @include dark {
      @apply border-gray;
    }
  }

  tr,
  td,
  th {
    vertical-align: middle;
  }

  th {
    @apply uppercase font-shield-black;
  }

  &.stripes-horizontal {
    tr:nth-of-type(odd) {
      @apply bg-white-blue;
      @include dark {
        @apply bg-gray;
      }
    }

    td,
    th {
      @apply border border-gray-light;
      @include dark {
        @apply border-gray;
      }
    }
  }

  &.stripe-first-col {
    tr,
    td,
    th {
      &:not([class*='bg-']) {
        @apply bg-white dark:bg-brand-navy;
      }
    }

    thead tr th:first-of-type,
    tbody tr td:first-of-type {
      @apply sticky text-left w-32 sm:w-1/5 max-w-xs z-10 border-r border-gray-light dark:border-gray;
      left: -1px;

      &:not([class*='bg-']) {
        @apply bg-white-blue dark:bg-brand-navy-light;
      }
    }
  }

  &.border-second-col {
    thead tr th:nth-of-type(2),
    tbody tr td:nth-of-type(2) {
      @apply border-r border-gray-light dark:border-gray;
    }
  }

  &.top {
    tr,
    td,
    th {
      vertical-align: top;
    }
  }
}
