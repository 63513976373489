@mixin fas {
  font-family: $fa-style-family;
  font-weight: $fa-style;
}

/*Font Faces*/
$asset-location: 'https://s3.topgolf.com/theme/';

@font-face {
  font-family: 'ShieldSans Regular';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src: url($asset-location + 'fonts/brand/shieldsans-regular.eot');
  src:
    url($asset-location + 'fonts/brand/shieldsans-regular.eot') format('embedded-opentype'),
    url($asset-location + 'fonts/brand/shieldsans-regular.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/shieldsans-regular.woff') format('woff');
}

@font-face {
  font-family: 'ShieldSans Light';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/shieldsans-light-webfont.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/shieldsans-light-webfont.woff') format('woff');
}

@font-face {
  font-family: 'ShieldSans Bold';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src: url($asset-location + 'fonts/brand/shieldsans-bold.eot');
  src:
    url($asset-location + 'fonts/brand/shieldsans-bold.eot') format('embedded-opentype'),
    url($asset-location + 'fonts/brand/shieldsans-bold.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/shieldsans-bold.woff') format('woff');
}

@font-face {
  font-family: 'ShieldSans Black';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src: url($asset-location + 'fonts/brand/shieldsans-black.eot');
  src:
    url($asset-location + 'fonts/brand/shieldsans-black.eot') format('embedded-opentype'),
    url($asset-location + 'fonts/brand/shieldsans-black.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/shieldsans-black.woff') format('woff');
}

@font-face {
  font-family: 'ShieldSans Light Condensed';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/shieldsans-lightcondensed-webfont.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/shieldsans-lightcondensed-webfont.woff') format('woff');
}

@font-face {
  font-family: 'ShieldSans Regular Condensed';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/shieldsans-regularcondensed-webfont.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/shieldsans-regularcondensed-webfont.woff') format('woff');
}

@font-face {
  font-family: 'ShieldSans SemiBold Condensed';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src: url($asset-location + 'fonts/brand/shieldsans-semiboldcondensed.eot');
  src:
    url($asset-location + 'fonts/brand/shieldsans-semiboldcondensed.eot')
      format('embedded-opentype'),
    url($asset-location + 'fonts/brand/shieldsans-semiboldcondensed.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/shieldsans-semiboldcondensed.woff') format('woff');
}

@font-face {
  font-family: 'Teeline Thin';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/tee-line-thin.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/tee-line-thin.woff') format('woff');
}

@font-face {
  font-family: 'Teeline Regular';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/tee-line-regular.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/tee-line-regular.woff') format('woff');
}

@font-face {
  font-family: 'Teeline Bold';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/tee-line-bold.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/tee-line-bold.woff') format('woff');
}

@font-face {
  font-family: 'Teeline Thin Italic';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/tee-line-thinitalic.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/tee-line-thinitalic.woff') format('woff');
}

@font-face {
  font-family: 'Teeline Regular Italic';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/tee-line-regularitalic.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/tee-line-regularitalic.woff') format('woff');
}

@font-face {
  font-family: 'Teeline Bold Italic';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src: url($asset-location + 'fonts/brand/tee-line-bolditalic.eot');
  src:
    url($asset-location + 'fonts/brand/tee-line-bolditalic.eot') format('embedded-opentype'),
    url($asset-location + 'fonts/brand/tee-line-bolditalic.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/tee-line-bolditalic.woff') format('woff');
}

@font-face {
  font-family: 'Teeline Extra Bold Italic';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src: url($asset-location + 'fonts/brand/tee-line-extrabolditalic.eot');
  src:
    url($asset-location + 'fonts/brand/tee-line-extrabolditalic.eot') format('embedded-opentype'),
    url($asset-location + 'fonts/brand/tee-line-extrabolditalic.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/tee-line-extrabolditalic.woff') format('woff');
}

@font-face {
  font-family: 'Teeline Black Italic';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src: url($asset-location + 'fonts/brand/tee-line-blackitalic.eot');
  src:
    url($asset-location + 'fonts/brand/tee-line-blackitalic.eot') format('embedded-opentype'),
    url($asset-location + 'fonts/brand/tee-line-blackitalic.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/tee-line-blackitalic.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 100';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/topgolf-100-webfont.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/topgolf-100-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 100 Italic';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/topgolf-100italic-webfont.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/topgolf-100italic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 300';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/topgolf-300-webfont.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/topgolf-300-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 300 Italic';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/topgolf-300italic-webfont.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/topgolf-300italic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 500';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/topgolf-500-webfont.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/topgolf-500-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 500 Italic';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/topgolf-500italic-webfont.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/topgolf-500italic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 800';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/topgolf-800-webfont.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/topgolf-800-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 800 Italic';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/topgolf-800italic-webfont.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/topgolf-800italic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 100 Condensed';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/TopgolfCondensed-100.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/TopgolfCondensed-100.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 300 Condensed';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/TopgolfCondensed-300.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/TopgolfCondensed-300.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 500 Condensed';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/TopgolfCondensed-500.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/TopgolfCondensed-500.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 700 Condensed';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/TopgolfCondensed-700.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/TopgolfCondensed-700.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 900 Condensed';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/TopgolfCondensed-900.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/TopgolfCondensed-900.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 100 Condensed Italic';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/TopgolfCondensedItalic-100.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/TopgolfCondensedItalic-100.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 300 Condensed Italic';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/TopgolfCondensedItalic-300.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/TopgolfCondensedItalic-300.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 500 Condensed Italic';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/TopgolfCondensedItalic-500.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/TopgolfCondensedItalic-500.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 700 Condensed Italic';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/TopgolfCondensedItalic-700.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/TopgolfCondensedItalic-700.woff') format('woff');
}

@font-face {
  font-family: 'Topgolf 900 Condensed Italic';
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    url($asset-location + 'fonts/brand/TopgolfCondensedItalic-900.woff2') format('woff2'),
    url($asset-location + 'fonts/brand/TopgolfCondensedItalic-900.woff') format('woff');
}

/* ICONS */

@font-face {
  font-family: 'icons';
  font-style: normal;
  font-weight: normal;
  src: url($asset-location + 'fonts/icon/icons-c28fa2dc49a72782018739ec5b18498c.eot');
  src:
    url($asset-location + 'fonts/icon/icons-c28fa2dc49a72782018739ec5b18498c.eot')
      format('embedded-opentype'),
    url($asset-location + 'fonts/icon/icons-c28fa2dc49a72782018739ec5b18498c.woff') format('woff'),
    url($asset-location + 'fonts/icon/icons-c28fa2dc49a72782018739ec5b18498c.ttf')
      format('truetype'),
    url($asset-location + 'fonts/icon/icons-c28fa2dc49a72782018739ec5b18498c.svg') format('svg');
}
