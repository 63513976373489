$dark-prefix: dark\:;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: 1.1em;
  margin: initial;

  &:not([class*='font-']) {
    @apply font-shield-bold;
  }
}

h1,
.h1 {
  @include base-text-size {
    @apply text-5xl;
  }
  @include base-text-transform {
    @apply uppercase;
  }

  &:not([class*='font-']) {
    @apply font-shield-black;
  }
}

h2,
.h2 {
  @include base-text-size {
    @apply text-4xl;
  }
  @include base-text-transform {
    @apply uppercase;
  }
}

h3,
.h3 {
  @include base-text-size {
    @apply text-3xl;
  }
  @include base-text-transform {
    @apply uppercase;
  }
}

h4,
.h4 {
  @include base-text-size {
    @apply text-2xl;
  }
  @include base-text-transform {
    @apply uppercase;
  }
}

h5,
.h5 {
  @include base-text-size {
    @apply text-xl;
  }

  &:not([class*='font-']) {
    @apply font-teeline-bold-italic;
  }
}

h6,
.h6 {
  @include base-text-size {
    @apply text-lg;
  }

  &:not([class*='font-']) {
    @apply font-teeline-bold-italic;
  }
}

@screen sm {
  h1,
  .h1 {
    @include base-text-size {
      @apply text-7xl;
    }
  }

  h2,
  .h2 {
    @include base-text-size {
      @apply text-6xl;
    }
  }

  h3,
  .h3 {
    @include base-text-size {
      @apply text-5xl;
    }
  }
}

p {
  margin: 0 0 1rem;
}

p:empty {
  display: none;
}

a,
.link,
button.link,
.prose a,
.prose button.link {
  @apply text-brand-bright border-b-2 border-accent-mint transition;
  cursor: pointer;
  text-decoration: none;
  @include dark {
    @apply text-white;

    &:not([class*='border-']) {
      @apply border-accent-neon;
    }

    &:hover {
      @apply text-accent-cyan;

      &:not([class*='border-']) {
        @apply border-accent-yellow;
      }
    }
  }

  &:hover {
    @apply text-brand border-accent-yellow;
  }

  &:focus {
    outline: 2px solid #0ff;
    outline-offset: 2px;
  }
}

a.no-decoration,
.no-decoration a {
  border-bottom: transparent none;
}

a.link-white,
button.link-white,
.link-white a {
  @apply text-white border-accent-cyan;

  &:hover,
  &:focus {
    @apply text-accent-cyan border-accent-yellow;
  }
}

.dark {
  a.#{$dark-prefix}link-white,
  button.#{$dark-prefix}link-white,
  .#{$dark-prefix}link-white a {
    @apply text-white;

    &:hover,
    &:focus {
      @apply text-accent-cyan;
    }
  }
}

b,
strong {
  font-weight: 700;
}

dfn {
  font-style: italic;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

blockquote {
  @apply text-gray-dark border-l-2 border-brand-bright;
  margin: 1rem 0;
  padding-left: 0.75rem;
}

cite {
  @apply text-gray;
  font-style: italic;

  &::before {
    content: '\2014 \00A0';
  }
}

.optanon-alert-box-wrapper .banner-policy-link {
  color: #fff !important;
}

.optanon-alert-box-wrapper .optanon-alert-box-button-middle {
  border-radius: 100px;
  padding: 0 !important;
}

.optanon-alert-box-wrapper .optanon-alert-box-button-middle button {
  color: #050786 !important;
  padding: 1rem 2rem;
}

/* UTILITY TYPE CLASSES */

.stagsubtitle {
  @apply font-teeline-bold-italic text-brand-bright dark:text-accent-cyan;

  display: block;
  font-size: 60%;
  line-height: 1.3;
  text-transform: none;
}

.stagsubtitle.bighits-red {
  @apply text-accent-red;
}

.has-linebreak::after {
  @apply bg-brand-hover;
  content: '';
  display: block;
  height: 0.25rem;
  margin: 1rem 0 0;
  max-width: 4rem;
  width: 40%;
  @include dark {
    @apply bg-accent-neon;
  }
}

.has-linebreak.text-center::after,
.text-center .has-linebreak::after {
  margin: 1rem auto 0;
}

.has-linebreak-center-m::after {
  margin: 1rem 0 0;
  @screen md {
    margin: 1rem auto 0 !important;
  }
}

.has-linebreak.has-linebreak-brand::after {
  @apply bg-brand;
}

.has-linebreak.has-linebreak-brand-hover::after {
  @apply bg-brand-bright;
}

.has-linebreak.has-linebreak-cyan::after {
  @apply bg-accent-cyan;
}

.has-linebreak.bighits-red::after {
  @apply bg-accent-red;
}

.has-linebreak.has-linebreak-orange::after {
  @apply bg-accent-orange;
}

.has-linebreak.has-linebreak-magenta::after {
  @apply bg-accent-magenta;
}

.has-linebreak.has-linebreak-gold::after {
  @apply bg-accent-gold;
}

.has-linebreak.has-linebreak-yellow::after {
  @apply bg-accent-yellow;
}

.has-linebreak.has-linebreak-seafoam::after {
  @apply bg-accent-seafoam;
}

.has-linebreak.has-linebreak-lilac::after {
  @apply bg-accent-lilac;
}

.has-linebreak.has-linebreak-neon::after {
  @apply bg-accent-neon;
}

.has-linebreak.has-linebreak-mint::after {
  @apply bg-accent-mint;
}

.prose {
  @apply max-w-full;
}

.slick-slide .text-brand-light,
.eventspace .text-brand-light,
.cp-content .text-brand-light,
.promo-content .text-brand-light {
  @apply text-brand-bright;
  @include dark {
    @apply text-accent-cyan;
  }
}

.typeinbox {
  @apply relative;
}

.typeinbox::before {
  @apply absolute bg-brand h-[calc(100%+12px)] -inset-x-2 -inset-y-1.5 rounded-sm w-[calc(100%+16px)];
  content: '';
  z-index: -1;
}

.typeinbox.brand-bright::before {
  @apply bg-brand-bright;
}

.typeinbox.cyan::before {
  @apply bg-accent-cyan;
}

.typeinbox.neon::before {
  @apply bg-accent-neon;
}

.typeinbox.yellow::before {
  @apply bg-accent-yellow;
}
