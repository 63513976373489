@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(0);
  }
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes pulse {
  from {
    transform: scale3d(0.1, 0.1, 0.1);
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-duration: 0.5s;
  animation-name: pulse;
}
