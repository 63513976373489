.header-wrap {
  @screen lg {
    @apply fixed;
    //fix for Container size in content
    + div {
      .container {
        max-width: 824px;
      }
    }
  }
  @screen xl {
    //fix for Container size in content
    + div {
      .container {
        max-width: 1020px;
      }
    }
  }
  @screen 2xl {
    //fix for Container size in content
    + div {
      .container {
        max-width: 1276px;
      }
    }
  }

  + div,
  .nav-top,
  .nav-location {
    @apply transition-all duration-500;
    left: 0;
    position: relative;
  }

  &.nav-open {
    + div,
    .nav-top,
    .nav-location {
      left: 25%;
    }
  }

  @screen lg {
    .nav-top,
    .nav-location {
      @apply transition-none;
      left: 0 !important;
      width: initial;
    }
  }
}

.sidebar {
  box-shadow: 0 3px 6px -2px rgb(21 34 49 / 44%);
  @screen lg {
    box-shadow: 12px 64px 64px 0 rgb(21 34 49 / 8%);
  }
  @include dark {
    box-shadow: 12px 64px 64px 0 rgb(255 255 255 / 4%);
  }
}

header {
  .logo-holder svg {
    height: 20px;
    width: auto;
    @screen sm {
      height: 24px;
    }
    @screen md {
      height: 26px;
    }
    @screen lg {
      height: auto;
      width: 80px;
    }
  }

  .nav-wrapper {
    left: -100vw;
    right: 100vw;
    width: 100vw;
    z-index: 9998;

    &.open {
      left: 0;
      max-width: 450px;
      overflow: auto;
      right: 0;
      @screen lg {
        overflow: initial;
      }
    }

    @screen lg {
      background: initial !important;
      bottom: initial;
      left: initial !important;
      overflow: visible;
      position: relative;
      right: initial !important;
      top: initial;
      width: initial;
    }
  }

  .nav-location {
    @screen lg {
      margin-bottom: 2vh;
      margin-top: 2vh;
    }

    .results {
      > div {
        @apply mb-4 pt-4 border-t border-white lg:border-brand lg:dark:border-brand-navy border-opacity-25 text-left;

        a,
        button {
          @apply block text-white lg:text-brand lg:dark:text-white;

          span {
            @apply text-xs my-2 block;
            text-transform: none;
          }

          .view {
            @apply inline-block border-b-2 border-accent-mint hover:border-accent-yellow dark:border-accent-neon dark:hover:border-accent-yellow text-white hover:text-accent-cyan lg:text-brand lg:hover:text-brand-bright lg:dark:text-white lg:dark:hover:text-accent-cyan text-xs font-shield-bold leading-none uppercase transition-all;
          }
        }

        &:first-child {
          @apply mt-0 pt-0 border-t-0;
        }
      }
    }
  }

  nav {
    ul.nav {
      @apply flex flex-col items-start gap-y-2;
      transition: none;

      &.opened {
        transform: translateX(-360px);
      }

      li {
        transition: none;

        a,
        button {
          @apply text-white hover:text-accent-cyan lg:text-brand lg:hover:text-brand-bright lg:dark:text-white lg:dark:hover:text-accent-cyan px-4 font-shield text-3xl lg:text-xl uppercase border-none leading-none;
          cursor: pointer;
          display: inline-block;
          font-weight: 400;
          padding-bottom: 1vh;
          padding-top: 1vh;

          &.active {
            @apply text-white lg:text-body lg:dark:text-white opacity-80 border-solid border-l-4 border-b-0 border-accent-cyan lg:border-accent-mint lg:dark:border-accent-cyan ml-1 pl-2;
          }
        }

        button.active:hover {
          @apply text-accent-cyan lg:text-brand-bright lg:dark:text-accent-cyan;
        }

        &.parent {
          transition: none;

          > {
            a,
            button {
              &::after {
                @apply pl-3 text-accent-neon lg:text-green-light lg:dark:text-accent-neon text-sm;
                @include icon;
                @include icon--chevron-right;
                vertical-align: initial;
              }

              &:hover {
                &::after {
                  @apply text-accent-yellow lg:text-accent-mint lg:dark:text-accent-yellow;
                }
              }
            }

            ul {
              display: none;

              li {
                a,
                button {
                  @apply text-base;
                  padding-bottom: 0.95vh;
                  padding-top: 0.95vh;
                }
              }
            }
          }

          &.open {
            left: 360px;
            position: absolute;
            top: 0;
            width: 100%;

            > button {
              @apply font-shield-bold;
            }

            > a,
            > button {
              cursor: default;
              outline: none !important;

              &:hover {
                @apply text-white lg:text-brand lg:dark:text-white;
              }

              &::after {
                display: none;
              }
            }

            ul {
              display: block;
            }
          }
        }
      }
    }

    .nav-back::before {
      @apply text-accent-neon lg:text-brand-bright lg:dark:text-accent-neon pr-3;
      @include fas;
      content: fa-content($fa-var-angle-left);
    }

    &.animate {
      ul.nav {
        transition: all 0.3s;

        li {
          transition: all 0.3s;

          &.parent {
            transition: all 0.3s;
          }
        }
      }
    }
  }

  .offsite {
    a,
    button {
      @apply block px-4 border-none font-teeline-italic text-accent-cyan hover:text-white lg:text-brand-bright lg:hover:text-brand lg:dark:text-accent-cyan lg:dark:hover:text-white;
      padding-bottom: 1vh;
      padding-top: 1vh;

      span {
        @apply inline-block text-center;
        display: inline-block;
        margin-right: 4px;
        width: 18px;
      }
    }
  }

  .social {
    @apply mt-8;
    @screen lg {
      margin-top: 2vh;
    }

    a,
    button {
      @apply border-none text-white hover:text-accent-cyan lg:text-brand lg:hover:text-brand-bright lg:dark:text-white lg:dark:hover:text-accent-cyan;
    }
  }
}
