.icon,
.mfp-close,
.mfp-arrow,
.view-map::after,
.read-more::after {
  @include icon;
}

@mixin icon--arrow-down {
  content: '\e001';
}

.icon--arrow-down::before,
.profile-list .item-header .icon-container .icon::before,
.vanity .focus.location-header .current-hours::after {
  @include icon--arrow-down;
}

@mixin icon--arrow-left {
  content: '\e002';
}

.icon--arrow-left::before,
.mfp-wrap.lightbox-ajax .mfp-image-holder .mfp-arrow-left::before,
.mfp-wrap.lightbox-gallery .mfp-image-holder .mfp-arrow-left::before,
.mfp-wrap.lightbox-image .mfp-image-holder .mfp-arrow-left::before,
.mfp-wrap.lightbox-inline .mfp-image-holder .mfp-arrow-left::before,
.mfp-wrap.lightbox-video .mfp-image-holder .mfp-arrow-left::before {
  @include icon--arrow-left;
}

@mixin icon--arrow-right {
  content: '\e003';
}

.icon--arrow-right::before,
.mfp-wrap.lightbox-ajax .mfp-image-holder .mfp-arrow-right::before,
.mfp-wrap.lightbox-gallery .mfp-image-holder .mfp-arrow-right::before,
.mfp-wrap.lightbox-image .mfp-image-holder .mfp-arrow-right::before,
.mfp-wrap.lightbox-inline .mfp-image-holder .mfp-arrow-right::before,
.mfp-wrap.lightbox-video .mfp-image-holder .mfp-arrow-right::before {
  @include icon--arrow-right;
}

@mixin icon--arrow-up {
  content: '\e004';
}

.membership-list .membership-list-item.active .membership-list-header .icon::before,
.icon--arrow-up::before {
  @include icon--arrow-up;
}

@mixin icon--bars {
  content: '\e005';
}

.icon--bars::before {
  @include icon--bars;
}

@mixin icon--caret-down {
  content: '\e006';
}

.icon--caret-down::before {
  @include icon--caret-down;
}

@mixin icon--caret-left {
  content: '\e007';
}

.icon--caret-left::before {
  @include icon--caret-left;
}

@mixin icon--caret-rect-right {
  content: '\e008';
}

.icon--caret-rect-right::before {
  @include icon--caret-rect-right;
}

@mixin icon--caret-right {
  content: '\e009';
}

.icon--caret-right::before {
  @include icon--caret-right;
}

@mixin icon--caret-up {
  content: '\e00a';
}

.icon--caret-up::before {
  @include icon--caret-up;
}

@mixin icon--check {
  content: '\e00b';
}

.icon--check::before {
  @include icon--check;
}

@mixin icon--chevron-circle-down {
  content: '\e00c';
}

.icon--chevron-circle-down::before {
  @include icon--chevron-circle-down;
}

@mixin icon--chevron-circle-left {
  content: '\e00d';
}

.icon--chevron-circle-left::before {
  @include icon--chevron-circle-left;
}

@mixin icon--chevron-circle-right {
  content: '\e00e';
}

.icon--chevron-circle-right::before {
  @include icon--chevron-circle-right;
}

@mixin icon--chevron-circle-up {
  content: '\e00f';
}

.icon--chevron-circle-up::before {
  @include icon--chevron-circle-up;
}

@mixin icon--chevron-down {
  content: '\e010';
}

.icon--chevron-down::before {
  @include icon--chevron-down;
}

@mixin icon--chevron-left {
  content: '\e011';
}

.icon--chevron-left::before {
  @include icon--chevron-left;
}

@mixin icon--chevron-right {
  content: '\e012';
}

.icon--chevron-right::before,
.view-map::after {
  @include icon--chevron-right;
}

@mixin icon--chevron-up {
  content: '\e013';
}

.icon--chevron-up::before {
  @include icon--chevron-up;
}

@mixin icon--close {
  content: '\e014';
}

.icon--close::before,
.mfp-wrap.lightbox-ajax .mfp-ajax-holder .mfp-close::before,
.mfp-wrap.lightbox-ajax .mfp-content .mfp-close::before,
.mfp-wrap.lightbox-ajax .mfp-iframe-holder .mfp-close::before,
.mfp-wrap.lightbox-ajax .mfp-image-holder .mfp-close::before,
.mfp-wrap.lightbox-ajax .mfp-inline-holder .mfp-close::before,
.mfp-wrap.lightbox-gallery .mfp-ajax-holder .mfp-close::before,
.mfp-wrap.lightbox-gallery .mfp-content .mfp-close::before,
.mfp-wrap.lightbox-gallery .mfp-iframe-holder .mfp-close::before,
.mfp-wrap.lightbox-gallery .mfp-image-holder .mfp-close::before,
.mfp-wrap.lightbox-gallery .mfp-inline-holder .mfp-close::before,
.mfp-wrap.lightbox-image .mfp-ajax-holder .mfp-close::before,
.mfp-wrap.lightbox-image .mfp-content .mfp-close::before,
.mfp-wrap.lightbox-image .mfp-iframe-holder .mfp-close::before,
.mfp-wrap.lightbox-image .mfp-image-holder .mfp-close::before,
.mfp-wrap.lightbox-image .mfp-inline-holder .mfp-close::before,
.mfp-wrap.lightbox-inline .mfp-ajax-holder .mfp-close::before,
.mfp-wrap.lightbox-inline .mfp-content .mfp-close::before,
.mfp-wrap.lightbox-inline .mfp-iframe-holder .mfp-close::before,
.mfp-wrap.lightbox-inline .mfp-image-holder .mfp-close::before,
.mfp-wrap.lightbox-inline .mfp-inline-holder .mfp-close::before,
.mfp-wrap.lightbox-video .mfp-ajax-holder .mfp-close::before,
.mfp-wrap.lightbox-video .mfp-content .mfp-close::before,
.mfp-wrap.lightbox-video .mfp-iframe-holder .mfp-close::before,
.mfp-wrap.lightbox-video .mfp-image-holder .mfp-close::before,
.mfp-wrap.lightbox-video .mfp-inline-holder .mfp-close::before {
  @include icon--close;
}

@mixin icon--comment-bubble {
  content: '\e015';
}

.icon--comment-bubble::before {
  @include icon--comment-bubble;
}

@mixin icon--gift-box {
  content: '\e016';
}

.icon--gift-box::before {
  @include icon--gift-box;
}

@mixin icon--gift-card {
  content: '\e017';
}

.icon--gift-card::before {
  @include icon--gift-card;
}

@mixin icon--info {
  content: '\e018';
}

.icon--info::before {
  @include icon--info;
}

@mixin icon--locations {
  content: '\e019';
}

.icon--locations::before {
  @include icon--locations;
}

@mixin icon--logo {
  content: '\e01a';
}

.icon--logo::before {
  @include icon--logo;
}

@mixin icon--phone {
  content: '\e01b';
}

.icon--phone::before {
  @include icon--phone;
}

@mixin icon--plus {
  content: '\e01c';
}

.icon--plus::before {
  @include icon--plus;
}

@mixin icon--profile {
  content: '\e01d';
}

.icon--profile::before {
  @include icon--profile;
}

@mixin icon--question {
  content: '\e01e';
}

.icon--question::before {
  @include icon--question;
}

@mixin icon--search {
  content: '\e01f';
}

.icon--search::before {
  @include icon--search;
}

@mixin icon--share {
  content: '\e020';
}

.icon--share::before {
  @include icon--share;
}

@mixin icon--shopping-cart {
  content: '\e021';
}

.icon--shopping-cart::before {
  @include icon--shopping-cart;
}

@mixin icon--turn-right {
  content: '\e022';
}

.icon--turn-right::before {
  @include icon--turn-right;
}
