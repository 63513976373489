@mixin icon {
  display: inline-block;
  font-family: 'icons' !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  vertical-align: middle;
}

@mixin label {
  @apply type-gotham-bold;
  color: #a7a7a7;
}

@mixin horizontal-divider($color: rgb(195, 195, 195)) {
  border-right: 0.0625rem solid $color;
}

@mixin soft-light-box($bg: theme('colors.white.DEFAULT'), $r: 0.375rem) {
  background: $bg;
  border-radius: $r;
}

@mixin top-lit {
  border-top: 0.0625rem solid #d7d7d7;
}

@mixin btn {
  border: 0 solid transparent !important;
  border-radius: 1.5625rem;
  box-shadow: none;
  display: inline-block;
  font-size: 0.75rem;
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  line-height: 1;
  margin-left: auto;
  margin-right: auto;
  padding: 1.1875rem 2.1875rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  text-transform: uppercase;
  vertical-align: top;

  &:hover {
    color: theme('colors.white.DEFAULT');
  }
}

@mixin primary-button {
  background: theme('colors.brand.DEFAULT');
  color: theme('colors.white.DEFAULT');
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
}

@mixin table-list {
  display: table;
  width: 100%;

  ul {
    display: table-row;

    li {
      display: table-cell;
    }
  }
}

@mixin base-text-size {
  &:not([class*='text-x']):not([class*='text-sm']):not([class*='text-tiny']):not(
      [class*='text-base']
    ):not([class*='text-md']):not([class*='text-lg']):not([class*='text-1']):not(
      [class*='text-2']
    ):not([class*='text-3']):not([class*='text-4']):not([class*='text-5']):not(
      [class*='text-6']
    ):not([class*='text-7']):not([class*='text-8']):not([class*='text-9']):not(
      [class*='text-oversize']
    ) {
    @content;
  }
}

@mixin base-text-transform {
  &:not([class*='normal-case']):not([class*='uppercase']):not([class*='lowercase']):not(
      [class*='capitalize']
    ) {
    @content;
  }
}

@mixin dark {
  .dark & {
    @content;
  }
}
