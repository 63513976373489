.btn,
.prose .btn {
  text-decoration: none !important;

  &:focus {
    outline: 2px solid #0ff !important;
    outline-offset: -6px !important;
  }

  @apply font-shield-bold inline-block tracking-wide leading-none transition uppercase;

  &:not([class*='border-']) {
    border: 2px solid #050786;
  }

  &:not([class*='text-xs']) {
    @apply text-sm;
  }

  &.items-center {
    @apply flex;
  }

  &:not([class*='text-']) {
    @apply text-white;
  }

  &:not([class*='rounded']) {
    @apply rounded-full;
  }

  &:not([class*='bg-']) {
    @apply bg-brand-bright;
    border-color: rgba(0, 4, 255);
    @include dark {
      @apply bg-white text-brand-bright;
      border-color: #fff;

      &:focus,
      &:hover {
        @apply bg-brand-bright border-brand-bright;
      }
    }

    &:focus,
    &:hover {
      &:not([class*='pointer-events-none']) {
        @apply bg-brand text-accent-cyan border-brand;
      }
    }
  }

  &:not([class*='px-']) {
    @apply px-7;
  }

  &:not([class*='py-']) {
    @apply py-3.5;
  }

  &.red {
    @apply bg-accent-red text-white border-accent-red;
    @include dark {
      @apply bg-accent-red text-white border-accent-red;

      &:focus,
      &:hover {
        @apply filter brightness-90 !bg-accent-red !text-white !border-accent-red;
      }
    }

    &:focus,
    &:hover {
      @apply filter brightness-90 !bg-accent-red !text-white !border-accent-red;
    }
  }

  &.white {
    @apply bg-white text-brand border-white;

    &:focus,
    &:hover {
      @apply uppercase !bg-brand-bright !border-brand-bright !text-accent-cyan;
    }
  }

  &.white-to-brand {
    @apply bg-white text-brand border-white;

    &:focus,
    &:hover {
      @apply uppercase !bg-brand !border-brand !text-accent-cyan;
    }
  }

  &.brand {
    @apply uppercase !bg-brand !border-brand !text-white;

    &:focus,
    &:hover {
      @apply uppercase !bg-brand-bright !border-brand-bright !text-accent-cyan;
    }
  }

  &.success {
    @apply bg-green-dark border-green-dark text-white;

    &:focus,
    &:hover {
      @apply uppercase !bg-green !border-green !text-white;
    }
  }

  &:focus,
  &:hover {
    @apply text-accent-cyan;
  }

  &.lg {
    @apply px-7 py-3.5 md:px-9 md:py-4 text-sm md:text-base;
  }

  &.sm {
    @apply px-5 py-2.5 text-xs;
  }

  &.xs {
    @apply px-3 py-1.5 text-xxs;
  }

  &.sm-mob {
    @apply px-5 py-2.5 md:px-7 md:py-3.5 text-xs md:text-sm;
  }

  &.sm-desk {
    @apply px-7 py-3.5 md:px-5 md:py-2.5 text-sm md:text-xs;
  }

  &.outline {
    @include dark {
      @apply bg-transparent border-white text-white;

      &:focus,
      &:hover {
        @apply bg-brand border-brand text-accent-cyan;
      }
    }
    @apply bg-transparent border-2 border-solid border-brand-bright text-brand-bright;
    @apply outline-none;

    &:focus,
    &:hover {
      @apply bg-brand border-brand text-accent-cyan;
    }

    &.brand {
      @apply uppercase !bg-transparent !border-brand !text-brand;

      &:focus,
      &:hover {
        @apply uppercase !bg-brand-bright !border-brand-bright !text-accent-cyan;
      }
    }

    &.cyan {
      @apply uppercase !bg-transparent !border-accent-cyan !text-white;

      &:focus,
      &:hover {
        @apply uppercase !bg-white !border-white !text-accent-cyan;
      }
    }

    &.white {
      @apply uppercase !bg-transparent !border-white !text-white;

      &:focus,
      &:hover {
        @apply uppercase !bg-white !border-white !text-brand-bright;
      }
    }
  }

  &:disabled,
  &.disabled {
    &,
    &:hover,
    &:focus {
      @apply opacity-25;
    }
  }
}

.select-container {
  @apply block;
}

.select-container select {
  appearance: none;
  background: url('https://s3.topgolf.com/uploads/icons/down-arrow-circle-cobalt.svg') no-repeat
    right 12px center;
  background-size: 16px 16px;

  &:not([class*='bg-']) {
    @apply bg-white;
  }

  &.transparent {
    background: url('https://s3.topgolf.com/uploads/icons/down-arrow-circle-cobalt.svg') no-repeat
      right 12px bottom 22px;
    background-size: 12px 12px;
    @include dark {
      background: url('https://s3.topgolf.com/uploads/icons/down-arrow-circle-neon.svg') no-repeat
        right 12px bottom 22px;
      background-size: 12px 12px;
    }
    @screen md {
      background-size: 16px 16px;
      background-position: right 12px bottom 20px;
    }
  }
}

.datepicker,
.flatpickr-input {
  background: url('https://s3.topgolf.com/uploads/icons/calendar-days.png') no-repeat right 12px
    center;
  background-size: 16px 15px;

  &:not([class*='bg-']) {
    @apply bg-white;
  }

  &.transparent {
    background: url('https://s3.topgolf.com/uploads/icons/down-arrow-circle-cobalt.svg') no-repeat
      right 12px bottom 22px;
    background-size: 12px 12px;
    @include dark {
      background: url('https://s3.topgolf.com/uploads/icons/down-arrow-circle-neon.svg') no-repeat
        right 12px bottom 22px;
      background-size: 12px 12px;
    }
    @screen md {
      background-size: 16px 16px;
      background-position: right 12px bottom 20px;
    }
  }
}

input:not([type='checkbox']):not([type='radio']),
.input,
textarea,
select {
  @apply p-4 text-base rounded-md;

  &:focus {
    outline: 2px solid #0ff !important;
    outline-offset: 2px !important;
  }

  &:not([class*='bg-']) {
    @apply bg-white;
  }

  &:not([class*='border']) {
    @apply border border-accent-lilac dark:border-accent-cyan;
  }

  &:not([class*='mb-']) {
    @apply mb-4;
  }

  &:not([class*='text-']) {
    @apply text-body;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }
}

input:not([type='checkbox']):not([type='radio']),
.input,
select {
  height: 3.75rem;
}

button:not(.btn) {
  &:focus {
    outline: 2px solid #0ff !important;
    outline-offset: 2px;
  }
}

button.btn {
  &:focus {
    outline: 2px solid #0ff !important;
    outline-offset: -6px !important;
  }

  &:not([class*='px-']) {
    @apply px-7;
  }

  &:not([class*='py-']) {
    @apply py-3.5;
  }

  &.sm {
    @apply px-5 py-2.5 text-xs;
  }

  &.xs {
    @apply px-3 py-1.5 text-xxs;
  }
}

button[type='submit']:not(.btn) {
  @apply text-base leading-none uppercase font-shield-bold;

  &:not([class*='bg-']) {
    @apply bg-brand-bright;

    &:focus,
    &:hover {
      @apply bg-brand text-accent-cyan;
    }
  }

  &:not([class*='rounded-']) {
    @apply rounded-md;
  }

  &:not([class*='text-']) {
    @apply text-white;
  }

  &:not([class*='px-']) {
    @apply px-7;
  }

  &:not([class*='py-']) {
    @apply py-3.5;
  }
}

input.radio {
  @apply w-5 h-5 p-0 flex items-center justify-center rounded-full border-2 border-brand-bright dark:border-accent-neon;
  appearance: none;

  &:checked {
    @apply bg-brand-bright dark:bg-accent-neon dark:border-accent-neon;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    @include dark {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    }
  }
}

.radio-group {
  label {
    @apply block border bg-white dark:bg-brand-navy border-accent-lilac dark:border-gray-warm mb-4 py-6 pr-4 pl-10 md:px-12 relative rounded-md cursor-pointer;

    .fineprint,
    .cta {
      @apply hidden;
    }

    input {
      @apply absolute left-3 md:left-4 w-4 h-4 md:w-5 md:h-5 p-0 flex items-center justify-center rounded-full border-2 border-brand-bright dark:border-accent-neon;
      appearance: none;
      top: 26px;

      &:checked {
        @apply bg-brand-bright dark:bg-accent-neon dark:border-accent-neon;
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
        @include dark {
          background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
        }

        ~ .fineprint,
        ~ .cta {
          @apply block;
        }
      }
    }

    > *:last-child {
      @apply mb-0;
    }

    &.disabled {
      cursor: not-allowed;

      > * {
        @apply opacity-75;
      }

      .res-alert {
        opacity: 1 !important;
      }

      input {
        @apply bg-transparent border-none;

        &::before {
          @apply text-base md:text-xl;
          @include fas;
          content: fa-content($fa-var-ban);
        }
      }
    }
  }

  .pav-alert {
    @apply relative flex items-start md:items-center gap-3 text-xs font-shield-bold leading-1 border-x border-t border-accent-lilac dark:border-gray-warm bg-white dark:bg-brand-navy rounded-t-md -mb-4 p-6 lg:px-8 z-10;

    &::before {
      @apply bg-white-red dark:bg-accent-red absolute -z-10 inset-3 rounded-md;
      content: '';
    }
  }

  .pav-promo {
    @apply relative items-start md:items-center gap-3 text-xs font-shield-bold leading-1 border-x border-t border-accent-lilac dark:border-gray-warm bg-white dark:bg-brand-navy rounded-t-md -mb-4 p-6 lg:px-8 z-10;

    &::before {
      @apply bg-brand border-2 border-brand-bright text-white absolute -z-10 inset-3 rounded-md;
      content: '';
    }
  }
}

input.toggle {
  appearance: none;

  + .toggleBox {
    @apply bg-body-light h-8 inline-block relative rounded-full transition-all w-12;

    &::after {
      @apply absolute bg-white block h-7 rounded-full transition-all w-7 z-10;
      content: '';
      left: 0.125rem;
      top: 0.125rem;
    }
  }

  &:checked {
    + .toggleBox {
      @apply bg-brand;

      &::after {
        left: 1.125rem;
      }
    }
  }

  &:focus {
    + .toggleBox {
      @apply outline-black;
      @include dark {
        @apply outline-white;
      }
    }
  }
}

.checkboxes {
  fieldset {
    @apply ml-4 mb-8;

    label {
      @apply font-shield mb-0 ml-2;
    }

    legend {
      margin-left: -1rem;
    }
  }

  label,
  legend {
    @apply text-sm font-teeline-bold-italic mb-2;
  }
}

.cognito {
  color: #434e57 !important;
  font-family: 'ShieldSans Regular', Arial, sans-serif !important;
  font-size: 16px !important;
  @include dark {
    color: #fff !important;
  }
}

.cognito strong {
  font-family: 'ShieldSans Bold', Arial, sans-serif !important;
  font-weight: normal !important;
}

.cognito .c-forms-form .c-html h3 {
  @apply font-shield-black uppercase leading-none mt-4;
  color: #434e57 !important;
  @include dark {
    color: #fff !important;
  }
}

.cognito .c-forms-form h4,
.cognito .c-forms-form h5 {
  color: #434e57 !important;
  font-family: 'ShieldSans Bold', Arial, sans-serif !important;
  font-size: 18px !important;
  @include dark {
    color: #fff !important;
  }
}

.cognito .c-label label,
.cognito .c-label {
  color: #434e57 !important;
  font-family: 'Teeline Bold Italic', Arial, sans-serif !important;
  font-size: 18px !important;
  line-height: 1.25 !important;
  padding-bottom: 16px !important;
  @include dark {
    color: #fff !important;
  }
}

.cognito .c-forms-form .c-number-spinner .c-number-spinner button {
  background: #f4f2ef !important;
}

.cognito .c-number-value {
  font-family: 'ShieldSans Bold', Arial, sans-serif !important;
  margin-bottom: 0 !important;
}

.cognito .c-span-24 .c-span-8,
.cognito .c-html {
  @apply border-2 border-gray-warm dark:border-0 rounded bg-white-blue dark:bg-brand-navy-light mt-2;

  .c-label {
    @apply block text-center;
    padding-bottom: 6px !important;
  }

  label {
    @apply uppercase;
    font-family: 'ShieldSans Bold', Arial, sans-serif !important;
    font-size: 12px !important;
    line-height: 1 !important;
    padding-bottom: 0 !important;
  }

  .c-content {
    @apply text-center text-3xl lg:text-6xl text-brand-light font-shield-black;
  }
}

.cognito .c-span-24 .c-span-8 {
  @apply mt-4 sm:mt-14;
  padding-top: 8px !important;
}

.cognito .c-html {
  padding: 24px !important;
}

.cognito .c-forms-form .c-helptext {
  @apply border-0 w-5/6;
  background: transparent !important;
  padding: 8px !important;
}

.cognito .c-field {
  padding: 16px 0 !important;
}

.cognito .c-forms-form input[type='radio']:checked + *::before {
  background: #050786 !important;
}

.cognito .c-forms-form .c-editor,
.cognito .c-forms-form .c-rating-scale-questions {
  color: #434e57 !important;
  font-family: 'ShieldSans Regular', Arial, sans-serif !important;
  font-size: 16px !important;
  @include dark {
    color: #fff !important;
  }
}

.cognito p,
.cognito li {
  color: #434e57 !important;
  font-family: 'ShieldSans Regular', Arial, sans-serif !important;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  @include dark {
    color: #fff !important;
  }
}

.cognito .c-forms-form a {
  font-family: 'ShieldSans Bold', Arial, sans-serif !important;
  text-decoration: none !important;
  @include dark {
    color: #fff !important;
  }
}

.cognito .c-forms-form button:not(.c-icon-button),
.cognito h4 a {
  background: #050786 !important;
  border: 0 !important;
  border-radius: 9999px !important;
  color: #fff !important;
  font-family: 'ShieldSans Bold', Arial, sans-serif !important;
  font-size: 14px !important;
  padding: 16px 32px !important;
  text-decoration: none !important;
  text-transform: uppercase;
}

.cognito button[type='submit'],
button.cog-button--submit {
  display: none !important;
}

.cognito .c-forms-form {
  background: transparent !important;
}

select > option {
  color: #000;
}
