.fred--active {
  @include dark {
    .mce-container,
    .mce-container *,
    .mce-widget,
    .mce-widget *,
    .mce-reset,
    .mce-menu,
    .mce-panel,
    .mce-tab,
    .mce-menu-item.mce-disabled,
    .mce-menu-item.mce-disabled:hover {
      background-color: theme('colors.black') !important;
      color: theme('colors.white.DEFAULT') !important;
    }

    .mce-btn .mce-txt,
    .mce-btn button,
    .mce-ico {
      color: theme('colors.white.DEFAULT') !important;
    }

    .mce-menu-item.mce-selected,
    .mce-menu-item:hover,
    .mce-menu-item:focus,
    .mce-tab.mce-active,
    .mce-tab:hover,
    .mce-menu-item.mce-menu-item-preview.mce-active {
      background-color: theme('colors.brand.navy.DEFAULT') !important;
    }

    .mce-combobox input,
    input.mce-textbox {
      height: 28px !important;
      padding: 0 4px !important;
    }
  }
}
