@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-size: 16px;
    @screen 2xl {
      font-size: 18px;
    }
  }
}

@layer components {
  .text-shadow {
    text-shadow:
      1px 1px 2px rgba(21, 34, 49, 0.25),
      2px 3px 10px rgba(21, 34, 49, 0.75);
  }

  .text-glow {
    text-shadow: 1px 2px 8px rgba(255, 255, 255, 0.5);
  }

  .text-stroke-brand {
    text-shadow:
      2px 2px 0 #050786,
      -2px -2px 0 #050786,
      2px -2px 0 #050786,
      -2px 2px 0 #050786,
      2px 2px 0 #050786;
  }

  .text-stroke-brand-thick {
    text-shadow:
      6px 6px 0 #050786,
      -6px -6px 0 #050786,
      6px -6px 0 #050786,
      -6px 6px 0 #050786,
      6px 6px 0 #050786;
  }
}

@layer utilities {
  .animated {
    animation-fill-mode: both;
  }

  .animate-delay-100 {
    animation-delay: 100ms;
  }

  .animate-delay-200 {
    animation-delay: 200ms;
  }

  .animate-delay-300 {
    animation-delay: 300ms;
  }

  .animate-delay-400 {
    animation-delay: 400ms;
  }

  .animate-delay-500 {
    animation-delay: 500ms;
  }

  .animate-delay-600 {
    animation-delay: 600ms;
  }

  .animate-delay-700 {
    animation-delay: 700ms;
  }

  .animate-delay-800 {
    animation-delay: 800ms;
  }

  .animate-delay-900 {
    animation-delay: 900ms;
  }

  .animate-delay-1 {
    animation-delay: 1s;
  }

  .animate-delay-2 {
    animation-delay: 2s;
  }

  .animate-delay-3 {
    animation-delay: 3s;
  }

  .animate-delay-4 {
    animation-delay: 4s;
  }

  .outline-black {
    outline: 2px dotted #000;
    outline-offset: 2px;
  }

  .outline-white {
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
}
