html {
  scroll-behavior: smooth;
}

body {
  @apply text-brand-navy bg-white font-shield text-base overflow-x-hidden leading-normal;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  @include dark {
    @apply text-white bg-brand-navy;
  }
}

::selection {
  background: #0ff;
  background: rgba(0, 255, 255, 1);
  color: #00f;
  text-shadow: none;
}

.skip-to-content-link {
  @apply absolute left-1/2 transform -translate-y-full -translate-x-1/2 -mt-4 z-50 opacity-0;

  &:focus {
    @apply translate-y-0 mt-1 opacity-100;
  }
}
